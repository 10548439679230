import { constructObjFromArray } from "./object";

export const RANGE_LABELS = [
    'Not yet due', 
    'Overdue',
    '1-30 days',
    '31-60 days',
    '61-90 days',
    '91+ days',
    'Paid',
    'Had Credit',
    'Voided'
];

export const RANGE_VALUES = [
    'not_yet_due',
    'overdue',
    'between_1_and_30_days',
    'between_31_and_60_days',
    'between_61_and_90_days',
    'over_91_days',
    'paid',
    'has_credit',
    'voided'
];

export const OVERDUE_RANGE_VALUES = [
    'between_1_and_30_days',
    'between_31_and_60_days',
    'between_61_and_90_days',
    'over_91_days',
];

export const OVERDUE_RANGE_LABELS = [
    '1-30 days',
    '31-60 days',
    '61-90 days',
    '91+ days'
];

export const BILLING_LABELS = [
    'Automatic Billing (QuickPay)',
    'Manual Billing',
]

export const BILLING_VALUES = [
    'quickpay',
    'manual_billing',
]

export const BE_RANGE_TO_LABEL_MAP = constructObjFromArray(RANGE_VALUES, RANGE_LABELS);
export const LABEL_TO_BE_RANGE_MAP = constructObjFromArray(RANGE_LABELS, RANGE_VALUES);

export const mapRangeLabelToBERange = (name) => {
    return LABEL_TO_BE_RANGE_MAP[name];
}

export const mapBERangeToLabel = (name) => {
    return BE_RANGE_TO_LABEL_MAP[name];
}