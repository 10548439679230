import { Box, Grid, Text, Tip } from 'grommet';
import React, { useContext, useState } from 'react';
import Section from '../../Section';
import { useRouter } from 'next/router';
import DateInput from '../../DateInput';
import useSWR from 'swr';
import { handleErrors, silentFetcher } from '../../../utils/errors';
import { NotificationsContext } from '../../NotificationsProvider';
import MessageBanner from '../../MessageBanner';
import MonthlySalesChart from '../../MonthlySalesChart';
import AveragePaymentPeriodChart from '../../AveragePaymentPeriodChart';
import AgingBalancesPieChart from '../../AgingBalancesPieChart';
import { formatCurrencySI } from '../../../utils/numbers';
import DottedText from '../../DottedText';
import {faCircleExclamation as IconAlertCircle} from '@fortawesome/pro-light-svg-icons'
import { faCircleInfo as IconCircleInfo, faAngleRight as IconArrowRight } from '@fortawesome/pro-light-svg-icons'
import FontAwesomeIcon from '../../FontAwesomeIcon';
import NotificationBanner from '../../NotificationBanner';

const AlertBanner = ({message, ctaText, onClick, showArrow=true}) => {
    return (
        <MessageBanner
            message={(
                <Text weight={600}>
                    {message}
                </Text>
            )}
            color="status-warning"
            icon={IconAlertCircle}
            iconProps={{customSize: '24px', border: false}}
            ctaText={(
                <Box direction="row" align="center" gap="xsmall">
                    <Text>{ctaText}</Text>
                    {showArrow && (<FontAwesomeIcon SelectedIcon={IconArrowRight} size="xsmall" />)}
                </Box>
            )}
            ctaProps={{margin: {horizontal: 'medium'}}}
            onClick={onClick}
            containerProps={{margin: {bottom: 'small'}}}
            innerContainerProps={{ gap: 'small' }}
            fill="horizontal"
            iconWrapperProps={{width: {min: '24px'}}}
        />
    )
}

const Dashboard = ({ size }) => {
    const [reportDate, setReportDate] = useState();
    const notificationCtx = useContext(NotificationsContext);
    const [hasAgingBalance, setHasAgingBalance] = useState(false);
    const router = useRouter();

    const fetcher = (url) => fetch(url)
        .then(handleErrors)
        .catch(notificationCtx.showNotification);

    // fetch balances to display in dashboard aging balance graph
    const {data: dashboardData, error: dashboardError} = useSWR(
        `/api/company/dashboard?${reportDate ? `report_date=${reportDate.toISOString()}&` : ''}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    const {data: unknownPaymentsData, error: unknownPaymentsError} = useSWR(
        `/api/company/unknown-payments`,
        silentFetcher,
        {
            revalidateOnFocus: false,
        }
    );

    const { data: customerSummaryData, error: customerSummaryDataError} = useSWR(
        `/api/company/customer-summary`,
        silentFetcher,
        {
            revalidateOnFocus: false,
        }
    );
    const pendingApplications = customerSummaryData?.total_applications ?? 0;

    const {data: notifications, error: notificationsError, mutate: mutateNotifications} = useSWR(
        `/api/company/notifications`,
        silentFetcher,
        {
            revalidateOnFocus: false,
        }
    );

    const {data: monthlySales, error: monthlySalesError} = useSWR(
        `/api/reports/monthly-sales`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    const {data: averagePaymentPeriod, error: averagePaymentPeriodError} = useSWR(
        `/api/reports/average-payment`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    const ChangeLabel = ({changeTotal, isLoading, label="Past 6 months", suffix='', prefix='',invert=false, format = ((data)=>(data)), tip}) => {
        if(changeTotal == undefined){
            return(<></>);
        }
        const positiveColor = invert ? "status-critical" : "barchart-primary";
        const negativeColor = invert ? "barchart-primary" : "status-critical";
        const isPositive = (changeTotal > 0)
        const isZero = (changeTotal == 0)
        const sign = isPositive ? "+" : ""
        const textColor = isZero ? "text-xweak" : (isPositive ? positiveColor : negativeColor)
        return(
            <Box direction="row" gap="xsmall" align="center" skeleton={isLoading ? {animation: 'fadeIn'} : undefined }>
                <Text color={textColor} weight={500}>
                    {prefix} {sign} {format(changeTotal)} {suffix}
                </Text>
                {
                    tip ? (
                        <Tip content={tip}
                            dropProps={
                                {
                                    width: {max: "medium"},
                                    align: { top: 'bottom', left: 'left' },
                                }
                            }
                            drop={{margin: "none"}}>
                            <DottedText color="text-weak">
                                {label}
                            </DottedText>
                        </Tip>
                    ) : <Text color="text-weak">{label}</Text>
                }
            </Box>
        )
    }
    return (
        <Box width={{max: '2020px'}}>
            {unknownPaymentsData && unknownPaymentsData?.unknown_payments_count > 0 && (
                <AlertBanner
                    message={`You have ${unknownPaymentsData?.unknown_payments_count} transaction${unknownPaymentsData?.unknown_payments_count > 1 ? 's' :''} that ${unknownPaymentsData?.unknown_payments_count > 1 ? 'are' :'is'} not associated with an invoice`}
                    ctaText='Fix now'
                    onClick={() => router.push('/transactions')}
                />
            )}
            {pendingApplications > 0 && (
                <AlertBanner
                    message={`You have ${pendingApplications} customer application${pendingApplications > 1 ? 's' :''} that need${pendingApplications > 1 ? '' :'s'} review`}
                    ctaText='Review'
                    onClick={() => router.push('/customers/applications')}
                />
            )}
            {notifications && notifications.notifications.length > 0 && (
                notifications.notifications.map((notification, index) => {
                    return (
                        <NotificationBanner
                            key={index}
                            notification={notification}
                            onDismiss={mutateNotifications}
                            showDismiss={notification.type === "StripeBankAccountSyncNotification"}
                            {...(notification.type !== "StripeBankAccountSyncNotification"
                                ? { ctaContainerProps: { margin: { horizontal: "medium" } } }
                                : {})}
                        />
                    );
                })
            )}
            <Grid margin={{top: 'small'}} columns={size === "small" ? ["auto"] : ['430px', 'auto','auto']}
                rows={size === "small" ? ["auto","auto","auto"] : ['auto', 'auto','auto']}
                gap="30px"
                areas={
                    (size === "small") ?
                        [
                            { name: 'aging_balance', start: [0, 0], end: [0, 0] },
                            { name: 'monthly_sales', start: [0, 1], end: [0, 1] },
                            { name: 'average_payment', start: [0, 2], end: [0, 2] },
                        ]
                        :
                        [
                            { name: 'aging_balance', start: [0, 0], end: [0, 1] },
                            { name: 'monthly_sales', start: [1, 0], end: [2, 0] },
                            { name: 'average_payment', start: [1, 1], end: [2, 2] },
                        ]}
            >

                <Box gridArea="aging_balance">
                    <Box
                        round="xsmall"
                        border={{round: 'small'}}
                        pad={{horizontal: 'small', top: 'medium'}}
                        elevation="medium"
                        background="background">
                        <Section
                            title={ <Tip
                                content={<Box pad="small"><Text><Text weight="bold">Aging balance</Text> summarizes all accounts receivable by age.</Text></Box>}
                                dropProps={{width: {max: "medium"}, align: { top: 'bottom', left: "left" }}}>
                                <Box direction="row" align="center" gap="xsmall">
                                    <Box>Aging balance</Box>
                                    <FontAwesomeIcon SelectedIcon={IconCircleInfo} color='text-weak' size='small'/>
                                </Box>
                            </Tip>}
                            control={<Box margin={{vertical: 'xsmall'}}>
                                <DateInput
                                    value={reportDate}
                                    onChange={setReportDate}
                                    labelPrefix="As of "
                                    defaultLabel="today" />
                            </Box>}
                            margin={{top: 'xsmall', horizontal: 'small'}}
                            contentProps={{pad: { vertical: 'medium'}}}
                        >
                            <AgingBalancesPieChart
                                dashboardData={dashboardData}
                                size={size}
                                isLoading={!dashboardData}
                            />
                        </Section>
                    </Box>
                </Box>
                <Box
                    gridArea='monthly_sales'
                    round="xsmall"
                    border={{round: 'small'}}
                    pad="medium"
                    elevation="medium"
                    background="background"
                    margin={{bottom: '0'}}
                    width={{ max: '750px' }}
                    fill="horizontal">
                    <Section
                        title={<Tip
                            content={
                                <Box gap="small" pad="small">
                                    <Text><Text weight="bold">Sales</Text> is the sum total of all invoice issued in a given period.</Text>
                                    <Text><Text weight="bold">Payments</Text> is the sum total of all receivables collected in a given period.</Text>
                                    <Text>The chart breaks down sales and payments by <Text weight="bold">month</Text>.</Text>
                                </Box>}
                            dropProps={{width: {max: "medium"},margin: "none",align: { top: 'bottom', left: 'left' }}}>
                            <Box direction="row" align="center" gap="xsmall">
                                <Box>Monthly sales</Box>
                                <FontAwesomeIcon SelectedIcon={IconCircleInfo} color='text-weak' size='small'/>
                            </Box>
                        </Tip>}
                        hideBorder
                        titleProps={
                            {
                                margin: {top: "xsmall", bottom: "none"}
                            }
                        }
                        subheader={<ChangeLabel
                            isLoading={!averagePaymentPeriod}
                            prefix='Total: '
                            tip={
                                <Box marign="none" pad="small">
                                    Period starting on {monthlySales?.start_month} and ending on {monthlySales?.end_month}.
                                </Box>
                            }
                            label={`Over ${monthlySales?.number_of_months} month${(monthlySales?.number_of_months > 1 ? 's' : '')}`}
                            changeTotal={monthlySales?.monthly_sales_change}
                            format={(data) => (formatCurrencySI(data, 'USD'))}
                        />}
                        margin={{top: 'xsmall', horizontal: 'small'}}
                        contentProps={{pad: { bottom: 'xsmall'}}}
                    >
                        <MonthlySalesChart isLoading={!monthlySales} data={monthlySales?.data}/>
                    </Section>
                </Box>
                <Box></Box>
                <Box
                    gridArea='average_payment'
                    round="xsmall"
                    border={{round: 'small'}}
                    pad="medium"
                    elevation="medium"
                    background="background"
                    margin={{bottom: 'xlarge'}}
                    width={{ max: '850px' }}
                    fill="horizontal">
                    <Section
                        title={ <Tip
                            content={
                                <Box gap="small" pad="small">
                                    <Text><Text weight="bold">Average payment period</Text> is the average number of days from issue date that customers take to settle an invoice.</Text>
                                </Box>}
                            dropProps={{width: {max: "medium"},margin: "none",align: { top: 'bottom', left: 'left' }}}>
                            <Box direction="row" align="center" gap="xsmall">
                                <Box>Average payment period</Box>
                                <FontAwesomeIcon SelectedIcon={IconCircleInfo} color='text-weak' size='small'/>
                            </Box>
                        </Tip>}
                        titleProps={
                            {
                                margin: {top: "xsmall", bottom: "none"}
                            }
                        }
                        subheader={<ChangeLabel
                            isLoading={!averagePaymentPeriod}
                            changeTotal={averagePaymentPeriod?.change}
                            suffix=" days"
                            label={(averagePaymentPeriod?.number_of_months == 0) ? 'Since last month': `Since last month`}
                            tip={
                                <Box marign="none" pad="small">
                                    {
                                        (averagePaymentPeriod?.number_of_months <= 1) ?
                                            "This graph will populate as payment volume increases."
                                            :
                                            `Comparing ${averagePaymentPeriod?.end_month} to ${averagePaymentPeriod?.start_month}.`
                                    }
                                </Box>
                            }
                            invert
                        />
                        }
                        hideBorder
                        margin={{top: 'xsmall', horizontal: 'small'}}
                        contentProps={{pad: { top: 'medium', bottom: 'small'}}}
                    >
                        <AveragePaymentPeriodChart data={averagePaymentPeriod?.data} change={averagePaymentPeriod?.change} netTerms={averagePaymentPeriod?.company_net_terms}/>
                    </Section>
                </Box>
            </Grid>
        </Box>
    );
}


export default Dashboard;
