import {Box, ResponsiveContext, Text, DateInput as GrommetDateInput} from "grommet";
import React, {useEffect, useState} from "react";
import {formatNumericDate, getToday} from "../utils/dates";
import {format} from 'date-fns';
import {
    faCalendar as IconCalendar
} from '@fortawesome/pro-light-svg-icons';
import FontAwesomeIcon from "./FontAwesomeIcon";
import Button from "./Button";
import DropButton from "./DropButton";
// Single date picker
const DateInput = ({
    onChange,
    value,
    defaultLabel,
    defaultValue,
    labelPrefix=''
}) => {
    const [open, setOpen] = useState(false);
    const [selectionDate, setSelectionDate] = useState((value || getToday()).toISOString());
    const formatDate = (date) => `${labelPrefix}${date ? formatNumericDate(date) : (defaultLabel || 'Date')}`;

    useEffect(() => {
        setSelectionDate((value || getToday()).toISOString());
    }, [value]);

    return (
        <Box align="center">
            <DropButton
                event={{section: 'date_input', element: 'input'}}
                hoverIndicator
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                dropProps={{
                    stretch: false,
                    align: {
                        top: 'bottom',
                        left: 'left'
                    }
                }}
                dropContent={(
                    <Box border round="xsmall" pad="small" flex={{shrink: 0}}>
                        <GrommetDateInput
                            inline
                            value={selectionDate}
                            onChange={({ value }) => {
                                setSelectionDate(value);
                                setOpen(false);
                                onChange(new Date(value));
                            }}
                            calendarProps={{
                                alignSelf: "center",
                                daysOfWeek: true,
                                bounds: ['2020-01-01', format(new Date(), 'yyyy-MM-dd')],
                                animate: false,
                                showAdjacentDays: "trim"
                            }}
                        />
                        <Box pad="xsmall">
                            <Button
                                event={{section: `date_input`, element: 'reset'}}
                                label={`Reset to ${defaultLabel}`}
                                onClick={() => {
                                    setSelectionDate(defaultValue);
                                    onChange(defaultValue);
                                    setOpen(false);
                                }}
                                secondary
                            />
                        </Box>
                    </Box>
                )}
            >
                <ResponsiveContext.Consumer>
                    {(size) => (
                        <Box
                            direction="row"
                            gap="xsmall"
                            align="center"
                            pad={size === 'small' ? 'small' : 'xsmall'}
                            border={{color: 'border'}}
                            round="xsmall">
                            <Box>
                                <FontAwesomeIcon SelectedIcon={IconCalendar} size="small" />
                            </Box>
                            <Text truncate={true}>
                                {formatDate(selectionDate)}
                            </Text>
                        </Box>
                    )}
                </ResponsiveContext.Consumer>
            </DropButton>
        </Box>
    );
}

export default DateInput;
